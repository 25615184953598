<template>
  <div class="div">
    <div class="item">
      <div class="time_css">TIME</div>
      <div class="nowtime_css">
        {{ nowTime }}
      </div>
      <div class="title">江干区首届儿童教育博览会</div>
      <div class="oldPayments">
        <div style="color:#fff;font-size：16px;width:16px;">已支付</div>
        <div class="Cube">
          <div v-for="(item, index) in user_num" :key="index">{{ item }}</div>
        </div>
        <div style="color:#fff;font-size：16px;margin-top:25px">人</div>
      </div>
      <div class="cjlist">
        <div class="iconbm">火速报名中</div>
        <div class="icon"><img src="@/assets/imgs/xsj.png" alt="" /></div>
        <div style="padding-top: -20px">
          <el-table
            :data="tableData"
            style="width: 100%; margin-top: -12px"
            :border="true"
          >
            <el-table-column label="团队名" align="center" prop="group_name">
            </el-table-column>
            <el-table-column
              label="成交"
              align="center"
              prop="total_orders"
              width="80"
            >
            </el-table-column>
            <el-table-column
              label="转发"
              align="center"
              prop="total_share_count"
            ></el-table-column>
            <el-table-column label="浏览" align="center" prop="liulan">
            </el-table-column>
            <el-table-column
              label="排名"
              align="center"
              type="index"
              width="80"
            >
            </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
    <div class="item">
      <div class="cjlist2">
        <div class="iconbm2">最新订单</div>
        <div class="icon2">
          <img src="@/assets/imgs/xsj2.png" alt="" />
        </div>
        <div style="padding-top: -20px">
          <el-table
            :data="tableData2"
            style="width: 100%; margin-top: -12px"
            :border="true"
          >
            <el-table-column align="center" prop="nick_name"> </el-table-column>
            <el-table-column align="center" prop="phone" width="140">
            </el-table-column>
            <el-table-column align="center" prop="date"> </el-table-column>
            <el-table-column align="center" prop="jgname" width="140">
            </el-table-column>
            <el-table-column align="center" prop="teacher"> </el-table-column>
          </el-table>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      token: "",
      num: 0,
      nowTime: new Date(),
      user_num: [],
      tableData: [],
      tableData2: [],
    };
  },
  mounted() {
    this.token = JSON.parse(this.$route.query.data);
    this.nowTimes();
    this.getPayUserList();
    this.initWebSocket();
    this.getListPageCount();
    this.getQuery();
  },
  destroyed() {
    //页面销毁时关闭长连接
  },
  methods: {
    getQuery() {
      let url =
        "/user/userRecommenderGroup/query?status=2&activity_id=" +
        this.token.id;
      this.$axios.get(url).then((res) => {
        this.tableData = res.data.data;
      });
    },
    getPayUserList() {
      let url =
        
        "/user/rechargerecord/queryPayUserList?activity_id=" +
        this.token.id;
      this.$axios.get(url).then((res) => {
        this.tableData2 = res.data.data;
      });
    }, //table2
    getListPageCount() {
      let url =
         "/user/rechargerecord/queryListPageCount";
      this.$axios.get(url).then((res) => {
        this.num = res.data.data + this.tableData2.length - 10;
        let a = this.num + "";
        this.user_num = [];
        for (let i = 0; i < a.length; i++) {
          this.user_num.push(a[i]);
        }
      });
    },

    //长连接
    initWebSocket() {
      //初始化weosocket
      const wsuri = "wss://curiousmore.com/wss/websocket";
      this.websock = new WebSocket(wsuri);
      this.websock.onmessage = this.websocketonmessage;
      this.websock.onopen = this.websocketonopen;
      this.websock.onerror = this.websocketonerror;
      this.websock.onclose = this.websocketclose;
      console.log("建立链接");
    },
    websocketonerror() {
      //连接建立失败重连
      // this.initWebSocket();
    },
    websocketonmessage(e) {
      // console.log(e);
      //数据接收
      let redata = JSON.parse(e.data);
      // console.log(redata);
      this.tableData2.unshift(redata);
      // console.log(this.tableData2);
      this.getListPageCount();
    },
    websocketclose(e) {
      //关闭
      console.log("断开连接", e);
    },
    //获取时间
    timeFormate(timeStamp) {
      let year = new Date(timeStamp).getFullYear();
      let month =
        new Date(timeStamp).getMonth() + 1 < 10
          ? "0" + (new Date(timeStamp).getMonth() + 1)
          : new Date(timeStamp).getMonth() + 1;
      let date =
        new Date(timeStamp).getDate() < 10
          ? "0" + new Date(timeStamp).getDate()
          : new Date(timeStamp).getDate();
      let hh =
        new Date(timeStamp).getHours() < 10
          ? "0" + new Date(timeStamp).getHours()
          : new Date(timeStamp).getHours();
      let mm =
        new Date(timeStamp).getMinutes() < 10
          ? "0" + new Date(timeStamp).getMinutes()
          : new Date(timeStamp).getMinutes();
      let ss =
        new Date(timeStamp).getSeconds() < 10
          ? "0" + new Date(timeStamp).getSeconds()
          : new Date(timeStamp).getSeconds();
      let week = new Date(timeStamp).getDay();
      let weeks = ["日", "一", "二", "三", "四", "五", "六"];
      let getWeek = "星期" + weeks[week];
      //   this.nowTime = year + "年" + month + "月" + date +"日"+" "+hh+":"+mm+':'+ss+getWeek  ;
      this.nowTime = hh + " : " + mm + " : " + ss;
    },
    nowTimes() {
      this.timeFormate(new Date());
      setInterval(this.nowTimes, 1000);
      this.clear();
    },
    clear() {
      clearInterval(this.nowTimes);
      this.nowTimes = null;
    },
  },
};
</script>

<style scoped lang="less">
* {
  margin: 0;
  padding: 0;
}

.iconbm {
  width: 200px;
  height: 40px;
  background: #ff3b3b;
  border-radius: 3px;
  margin: 0 auto;
  position: relative;
  top: 20px;
  line-height: 40px;
  color: #fff;
  text-align: center;
  font-size: 20px;
}

.icon {
  position: relative;
  left: 264px;
  top: 4px;
}

.cjlist {
  width: 583px;
  padding-bottom: 40px;
  background: #ffffff;
  border-radius: 10px;
  margin: 20px auto;
}

.iconbm2 {
  width: 200px;
  height: 40px;
  background: #FFA53E;
  border-radius: 3px;
  margin: 0 auto;
  position: relative;
  top: 20px;
  line-height: 40px;
  color: #fff;
  text-align: center;
  font-size: 20px;
}

.icon2 {
  width: 100px;
  position: relative;
  left: 264px;
  top: 4px;
}

.cjlist2 {
  width: 583px;
  padding-bottom: 40px;
  background: #ffffff;
  border-radius: 10px;
  margin: 170px auto 0 auto;
}

.Cube {
  display: flex;
  justify-content: center;
}

.Cube div {
  width: 50px;
  height: 40px;
  margin: 10px 10px;
  background-color: #fff;
  font-size: 21px;
  line-height: 40px;
  text-align: center;
}

.oldPayments {
  display: flex;
  justify-content: center;
}

.time_css {
  width: 138px;
  height: 81px;
  font-size: 48px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 81px;
  color: #ffffff;
  position: relative;
  top: 22px;
  left: 68px;
}

.div {
  width: 100% !important;
  height: 100%;
  display: flex;
  justify-content: center;
}

.item {
  background-color: #59669B;
  border: 1px solid #ffffff;
  width: 50%;
  height: 100%;
}

.nowtime_css {
  width: 130px;
  height: 37px;
  font-size: 26px;
  font-family: PingFang SC;
  font-weight: 300;
  line-height: 37px;
  color: #ffffff;
  position: relative;
  top: 16px;
  left: 68px;
}

.title {
  width: 368px;
  height: 54px;
  background: #ffffff;
  box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.16);
  border-radius: 10px;
  margin: 40px auto;
  text-align: center;
  font-size: 28px;
  font-family: PingFang SC;
  font-weight: 400;
  line-height: 54px;
  color: #000000;
}
</style>